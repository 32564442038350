import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import Label from '../../Label';


// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  notifications: PropTypes.object,
  user:PropTypes.object
};

export default function NavSectionVertical({ user, notifications, navConfig, isCollapse = false, ...other }) {
  console.log("notificaciones,2", notifications)
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>
          { group.items.map((list) => {
            // console.log("notificacions.", notifications[list.llave] )
            if (notifications&&notifications[list.llave]){
              list.info = 
              (
                <Label variant="outlined" color="error">
                  +{notifications[list.llave]}
                </Label>
              )
            } 
            
            // console.log('user roles', user.roles.some(e => e.Name === list.role))
           return user.roles.some(e => e.name === list.role) && <NavListRoot key={list.title} list={list} isCollapse={isCollapse} notifications={notifications}/>
            // <NavListRoot key={list.title} list={list} isCollapse={isCollapse}/>
          
            
})}
        </List>
      ))}
    </Box>
  );
}
